import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alert: AlertController,
    public toastCtrl: ToastController) { }

  async showAlert(title: string, message: string, handler?: any, dismiss?: any): Promise<void> {
    let buttons = [];
    if (handler) {
      buttons.push({
        text: 'Ok',
        handler: handler
      });
    } else {
      buttons.push('Ok');
    }
    const alert = await this.alert.create({
      header: title,
      subHeader: message,
      buttons: buttons
    });
    if (dismiss) alert.onDidDismiss().then(() => dismiss);
    return alert.present();
  }

  async handleError(err: any) {
    let message: string;
    const type: string = typeof err.message;
    if (type != "string") {
      message = JSON.stringify(err.message);
    } else {
      message = err.message;
    };
    const alert = await this.alert.create({
      header: 'Error',
      subHeader: message,
      buttons: ['Ok']
    });
    await alert.present();
  }

  async showConfirm(title: string, message: string, btnText: string, handler: any, cancelHandler?: any): Promise<void> {
    let btns = [];
    btns.push({
      text: btnText,
      handler: handler
    });
    if (cancelHandler) {
      btns.push(cancelHandler);
    } else {
      btns.push({
        text: 'Cancel',
        role: 'cancel',
        handler: () => console.log('Cancelled confirm')
      });
    }
    const confirm = await this.alert.create({
      header: title,
      subHeader: message,
      buttons: btns
    });
    confirm.present();
  }

  async showToast(message: string, header?: string, duration?: number): Promise<void> {
    let t: number = duration || 2000;
    const opt: any = {
      'header': header,
      'message': message,
      'duration': t
    };
    const toast = await this.toastCtrl.create(opt);
    await toast.present();
  }
}
