import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";

import { ConfigService } from "./config.service";
import { AlertService } from "./alert.service";
import { ImageService } from "./image.service";
import {
  IClientModel,
  IClient,
  INewClient,
  IUser,
  ISession,
  ISessionNote,
  IAssessCategory,
  IAssessCategoryItem,
  IAssessment,
  IReferralTemplate,
  IReferralContact
} from "../models/models";

import * as _ from "lodash";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class StaffService {
  constructor(
    private config: ConfigService,
    private image: ImageService,
    private http: HttpClient,
    private alert: AlertService
  ) {}

  getBodyParts(): Observable<any> {
    // let params = new HttpParams().set('clientId', clientId.toString());
    return this.http
      .get(this.config.api + "getBodyParts", { params: undefined })
      // .pipe
      // tap((data: any) => { console.log("Body Part Data", data); })
      // ();
  }

  getClients(user: IUser): Observable<IClientModel> {
    let params: HttpParams = new HttpParams().set(
      "tenantId",
      user.tenantId.toString()
    );
    return this.http
      .get(this.config.api + "getclients", { params: params })
      .pipe(
        map((data: any) => {
          let clients: IClient[] = [];
          if (data.success) {
            data.data.forEach((c: IClient) => {
              let avatar: string;
              if (c.avatar != undefined) {
                avatar = c.avatar;
              } else {
                avatar = this.image.initialsAvatar(c.firstname, c.lastname, 40);
              }
              clients.push({
                clientId: c.clientId,
                staffId: c.staffId,
                staff: c.staff,
                firstname: c.firstname,
                lastname: c.lastname,
                avatar: avatar
              });
            });
          }
          clients = _.sortBy(clients, [function(o) { 
            const n = o.lastname.toLowerCase();
            return n; 
          }]);

          let clientData: IClientModel = {
            success: data.success,
            message: data.message,
            clients: clients
          };
          return clientData;
        })
      );
  }

  addClient(client: INewClient, token: string): Observable<{}> {
    const params: any = { client: client, token: token };
    return this.http.post(this.config.api + "addclient", params);
  }

  // TODO: [HUB-32] Fix getClientData(clientId: number) as it is basically copying getSessions
  getClientData(clientId: number, tenantId: number): Promise<any> {
    let params = new HttpParams()
      .set("clientId", clientId.toString())
      .set("tenantId", tenantId.toString());
    return this.http
      .get(this.config.api + "getclientdata", { params: params })
      .pipe(
        tap(d => {
          console.log("getClientData()", d);
        }),
        map((d: any) => d)
      )
      .toPromise();
  }

  getClientAssessments(
    clientId: number,
    tenantId: number
  ): Observable<IAssessment[]> {
    let params = new HttpParams().set("clientId", clientId.toString());
    params = params.set("tenantId", tenantId.toString());
    return this.http
      .get(this.config.api + "getClientAssessments", { params: params })
      .pipe(
        tap(r => {
          console.log("getClientAssessments() response", r);
        }),
        map((data: any) => {
          let assessment: IAssessment[];
          if (data.success) assessment = data.assessments;
          return assessment;
        })
      );
  }

  getClientAssessment(assessmentId: number): Observable<IAssessment> {
    let params = new HttpParams().set("assessmentId", assessmentId.toString());
    return this.http
      .get(`${this.config.api}getclientassessment`, { params })
      .pipe(
        // tap(r => {
        //   console.log("getClientAssessment() Response", r);
        // }),
        map((data: any) => {
          let assessment: IAssessment = null;
          if (data.success) {
            assessment = data.assessment;
            assessment.assessmentDate = moment(assessment.assessmentDate);
            if (assessment.list.length > 0) {
              assessment.list = _.map(assessment.list, (o: IAssessCategory) => {
                return {
                  categoryId: o.categoryId,
                  category: o.category,
                  selected: true,
                  items: o.items
                };
              });

              _.forEach(assessment.list, (o: IAssessCategory) => {
                o.items = _.map(o.items, (i: IAssessCategoryItem) => {
                  return {
                    id: i.id,
                    subCategory: i.subCategory,
                    description: i.description,
                    units: i.units,
                    selected: true,
                    form: i.form
                  };
                });
              });
            }
          } else {
            this.alert.handleError(data);
          }
          return assessment;
        })
      );
  }

  createAssessment(
    clientId: number,
    tenantId: number,
    assessment: IAssessment
  ) {
    const params: any = {
      clientId: clientId,
      assessmentDate: assessment.assessmentDate.format("YYYY-MM-DDThh:mm:ss"),
      staffId: assessment.staffId,
      tenantId: tenantId
    };
    return this.http
      .post(this.config.api + "createassessment", params)
      .pipe
      // tap(r => { console.log("createAssessment() response", r) })
      ();
  }

  updateAssessment(assessment: IAssessment): Observable<{}> {
    const params: any = {
      assessmentId: assessment.assessmentId,
      assessmentDate: assessment.assessmentDate
        .set({ hour: 0, minute: 0, second: 0 })
        .format("YYYY-MM-DDThh:mm:ss"),
      staffId: assessment.staffId
    };
    console.log("updateAssessment()", params);
    return this.http.put(this.config.api + "updateassessment", params).pipe(
      tap(r => {
        console.log("updateAssessment() response", r);
      })
    );
  }

  getAssessmentCategories(): Observable<IAssessCategory[]> {
    return this.http
      .get(this.config.api + "getAssessmentCategories", { params: null })
      .pipe(
        tap(r => {
          console.log("getAssessmentCategories() response", r);
        }),
        map((data: any) => {
          let categories: IAssessCategory[];
          if (data.success) {
            categories = _.map(data.categories, function(o) {
              let map: IAssessCategory = {
                categoryId: o.categoryId,
                category: o.category,
                selected: false,
                items: []
              };
              return map;
            });
          } else {
            this.alert.handleError(data);
          }
          return categories;
        })
      );
  }

  getAssessmentSubCategories(
    categoryId: number
  ): Observable<IAssessCategoryItem[]> {
    let params = new HttpParams().set("categoryId", categoryId.toString());
    return this.http
      .get(this.config.api + "getAssessmentSubCategories", { params: params })
      .pipe(
        // tap(r => { console.log("getAssessmentSubCategories() response", r) }),
        map((data: any) => {
          let items: IAssessCategoryItem[];
          if (data.success) {
            items = _.map(data.subCategories, function(o: IAssessCategoryItem) {
              let map: IAssessCategoryItem = {
                id: o.id,
                subCategory: o.subCategory,
                description: o.description,
                units: o.units,
                selected: false,
                form: o.form
              };
              return map;
            });
          } else {
            this.alert.handleError(data);
          }
          return items;
        })
      );
  }

  saveAssessmentItem(
    assessmentId: number,
    item: IAssessCategoryItem
  ): Observable<{}> {
    let data = {
      assessmentId,
      item
    };
    return this.http.post(`${this.config.api}addassessmentitem`, data);
  }

  getClientSessions(
    clientId: number,
    tenantId: number
  ): Observable<ISession[]> {
    let params = new HttpParams().set("clientId", clientId.toString());
    params = params.set("tenantId", tenantId.toString());
    return this.http
      .get(this.config.api + "getclientsessions", { params: params })
      .pipe(
        tap(r => {
          console.log("getClientSessions() response", r);
        }),
        map((data: any) => {
          let sessions: ISession[];
          if (data.success) sessions = data.sessions;
          return sessions;
        })
      );
  }

  createSession(clientId: number, tenantId: number, session: ISession) {
    const params: any = {
      clientId: clientId,
      sessionDate: session.sessionDate.format("YYYY-MM-DDThh:mm:ss"),
      staffId: session.staffId,
      tenantId: tenantId
    };
    console.log("createSession()", params);
    return this.http.post(this.config.api + "createsession", params);
  }

  updateSession(session: ISession): Observable<{}> {
    const params: any = {
      sessionId: session.id,
      sessionDate: session.sessionDate.format("YYYY-MM-DDThh:mm:ss"),
      staffId: session.staffId
    };
    console.log("updateSession()", params);
    return this.http.put(this.config.api + "updatesession", params);
  }

  getSessionNotes(sessionId: number): Observable<ISessionNote[]> {
    let params = new HttpParams().set("sessionId", sessionId.toString());
    return this.http
      .get(this.config.api + "getsessionnotes", { params: params })
      .pipe(
        tap(r => {
          // console.log("Get Session Notes Response", r);
        }),
        map((data: any) => {
          let responseData;
          let notes: ISessionNote[] = [];
          if (data.success) {
            notes = data.notes;

            responseData = {
              staffId: data.session.staffId,
              date: data.session.sessionDate,
              notes: notes
            };
          }

          return responseData;
        })
      );
  }

  createSessionNote(note: ISessionNote): Observable<{}> {
    const params: any = {
      sessionId: note.sessionId,
      note: note.note,
      injuryId: note.injuryId
    };
    console.log("createSessionNote()", params);
    return this.http.post(this.config.api + "addsessionnote", params);
  }

  updateSessionNote(note: ISessionNote): Observable<{}> {
    const params: any = {
      noteId: note.id,
      note: note.note,
      injuryId: note.injuryId
    };
    console.log("updateSessionNote()", params);
    return this.http.put(this.config.api + "updatesessionnote", params);
  }

  getStaff(user: IUser): Observable<{}> {
    let params = new HttpParams().set("tenantId", user.tenantId.toString());
    return this.http.get(this.config.api + "getstaff", { params: params });
  }

  getClientInjuries(clientId: number, tenantId: number): Promise<any> {
    const params = new HttpParams()
      .set("clientId", clientId.toString())
      .set("tenantId", tenantId.toString());
    return this.http
      .get(this.config.api + "getInjuries", { params })

      .pipe(
        // tap((data: any) => { console.log("injuries data", data) }),
        map((data: any) => {
          let injuries = data.injuries;
          return injuries;
        })
      )
      .toPromise();
  }

  // Referrals Templates
  createReferralTemplate(template: IReferralTemplate, tenantId: number): Observable<any> {
    const params: any = {
      name: template.name,
      content: template.content,
      typeId: 1,
      tenantId: tenantId
    };
    console.log("createReferralTemplate()", params);
    return this.http.post(this.config.api + "createReferralTemplate", params);
  }

  updateReferralTemplate(template: IReferralTemplate): Observable<any> {
    const params: any = {
      name: template.name,
      content: template.content,
      typeId: 1,
      referralId: template.id
    };
    console.log("updateReferralTemplate()", params);
    return this.http.post(this.config.api + "updateReferralTemplate", params);
  }

  deleteReferralTemplate(referralId: number): Observable<any> {
    const params: any = {
      body: {
        referralId
      }
    };
    // console.log("deleteReferralTemplate()", params);
    return this.http.delete(this.config.api + "deleteReferralTemplate", params);
  }

  getReferralTemplates(tenantId: number): Observable<any> {
    let params = new HttpParams().set("tenantId", tenantId.toString());
    return this.http
      .get(this.config.api + "getReferralTemplates", { params: params })
      .pipe(
        // tap(r => {
        //   console.log("getReferralTemplates() response", r);
        // }),
        map((data: any) => {

          let t = _.sortBy(data.templates, [function(o) { 
            const n = o.name.toLowerCase();
            return n; 
          }]);

          data.templates = t;
          return data;
        })
      );
  }

  getReferralTemplate(tenantId: number): Observable<any> {
    let params = new HttpParams().set("referralId", tenantId.toString());
    return this.http
      .get(this.config.api + "getReferralTemplate", { params: params })
      .pipe(
        tap(r => {
          console.log("getReferralTemplate() response", r);
        }),
        map((data: any) => {
          return data;
        })
      );
  }

  // Referrals Contacts
  // OK
  createReferralContact(contact: IReferralContact, tenantId: number): Observable<any> {
    const params: any = {
      tenantId: tenantId,
      title: contact.title,
      firstname: contact.firstname,
      lastname: contact.lastname,
      email: contact.email,
      phone: contact.phone,
      fax: contact.fax,
      mobile: contact.mobile,
      company: contact.company,
      address1: contact.address1,
      address2: contact.address2,
      town: contact.town,
      state: contact.state,
      country: contact.country,
      postcode: contact.postcode,
      abn: contact.abn,
      account: contact.account,
      website: contact.website,
      notes: contact.notes,
      typeId: 1
    };
    // console.log("createReferralContact()", params);
    return this.http.post(this.config.api + "createReferralContact", params);
  }
  // NOT OK
  updateReferralContact(contact: IReferralContact): Observable<any> {
    const params: any = {
      contactId: contact.contactId,
      title: contact.title,
      firstname: contact.firstname,
      lastname: contact.lastname,
      email: contact.email,
      phone: contact.phone,
      fax: contact.fax,
      mobile: contact.mobile,
      company: contact.company,
      address1: contact.address1,
      address2: contact.address2,
      town: contact.town,
      state: contact.state,
      country: contact.country,
      postcode: contact.postcode,
      abn: contact.abn,
      account: contact.account,
      website: contact.website,
      notes: contact.notes,
      typeId: 1
    };
    // console.log("updateReferralContact()", params);
    return this.http.put(this.config.api + "updateReferralContact", params);
  }
  // OK
  deleteReferralContact(contactId: number): Observable<any> {
    const params: any = {
      body: {
        contactId: contactId
      }
    };
    // console.log("deleteReferralContact()", params);
    return this.http.delete(this.config.api + "deleteReferralContact", params);
  }
  // OK
  getReferralContacts(tenantId: number): Observable<any> {
    let params = new HttpParams().set("tenantId", tenantId.toString());
    return this.http
      .get(this.config.api + "getReferralContacts", { params: params })
      .pipe(
        // tap(r => {
        //   console.log("getReferralContacts() response", r);
        // }),
        map((data: any) => {
          let contacts: IReferralContact[] = [];
          if (data.success) {
            data.contacts.forEach((c: IReferralContact) => {
              let avatar: string;
              if (c.avatar != undefined) {
                avatar = c.avatar;
              } else {
                avatar = this.image.initialsAvatar(c.firstname, c.lastname, 40);
              }
              contacts.push({
                contactId: c.contactId,
                title: c.title,
                firstname: c.firstname,
                lastname: c.lastname,
                email: c.email,
                phone: c.phone,
                fax: c.fax,
                mobile: c.mobile,
                company: c.company,
                address1: c.address1,
                address2: c.address2,
                town: c.town,
                state: c.state,
                country: c.country,
                postcode: c.postcode,
                abn: c.abn,
                account: c.account,
                website: c.website,
                notes: c.notes,
                typeId: c.typeId,
                avatar: avatar
              });
            });
          }
          contacts = _.sortBy(contacts,  [function(o) { 
            const n = o.lastname.toLowerCase();
            return n; 
          }]);

          const response: any = {
            success: data.success,
            message: data.message,
            contacts: contacts
          };
          return response;
        })
      );
  }
  // NOT CHECKED
  getReferralContact(contactId: number): Observable<any> {
    let params = new HttpParams().set("tenantId", contactId.toString());
    return this.http
      .get(this.config.api + "getReferralContact", { params: params })
      .pipe(
        tap(r => {
          console.log("getReferralContact() response", r);
        }),
        map((data: any) => {
          return data;
        })
      );
  }

  updateClientFormAssignment(clientId: number, tenantId: number, forms): Observable<any> {
    const body = {
      clientId,
      tenantId,
      forms
    }
    return this.http.put(this.config.api + "updateClientFormAssignment", body)
    .pipe(
      tap(r => {
        console.log("updateClientFormAssignment() response", r);
      }),
      map((data: any) => {
        return data;
      })
    );
  }

  updateClientDetails(client): Observable<any> {
    return this.http.put(this.config.api + "editClient", client)
    .pipe(
      tap(r => {
        console.log("updateClientDetails() response", r);
      }),
      map((data: any) => {
        return data;
      })
    );
  }

  deleteClient(clientId: number, staffId: number): Observable<any> {
    const params: any = {
      body: {
        clientId,
        staffId
      }
    };
    return this.http.delete(this.config.api + 'deleteClient', params)
    .pipe(
      tap(r => {
        console.log("deleteClient() response", r);
      }),
      map((data: any) => {
        return data;
      })
    );
  }

  generateReport(data): Observable<any> {
    return this.http.post(this.config.api + 'generateReport', data, {
      responseType: "blob"
    });
  }
}
