import { Component } from "@angular/core";
import {
  MenuController,
  NavController,
  Platform,
  Events
} from "@ionic/angular";
import { environment } from '../environments/environment';

import { HeaderColor } from "@ionic-native/header-color/ngx";

import { Plugins, StatusBarStyle } from '@capacitor/core';
const { StatusBar } = Plugins;
const { SplashScreen } = Plugins;

import {
  AuthService,
  ImageService,
  StorageService,
  ThemeService
} from "./services/shared-services";
import { IUser } from "./models/models";

import * as firebase from "firebase/app";
import "firebase/analytics";

import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  menuImage: string = "../assets/images/logo.png";
  tenantUrl: string = "";
  rootUrl: string = "";
  admin: boolean = false;
  public settingsPages = [
    { title: "My Profile", link: "settings/profile", icon: "contact" },
    { title: "Business Settings", link: "settings/team", icon: "home" },
    { title: "Client Form Editor", link: "settings/forms", icon: "construct" },
    { title: "Referral Templates", link: "referral/templates", icon: "today" }
  ];
  public appPages = [
    // { title: 'Client Schedule', link: '/' },
    { title: "Client Directory", link: "/" },
    { title: "Referral Contacts", link: "referral/contacts" }
  ];

  constructor(
    private swUpdate: SwUpdate,
    private platform: Platform,
    private headerColor: HeaderColor,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private auth: AuthService,
    private image: ImageService,
    private store: StorageService,
    private theme: ThemeService,
    public events: Events
  ) {
    this.initializeApp();
    this.listenForEvents();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });

      let firebaseConfig = {
        apiKey: "AIzaSyCOFNHN-RiS0msSdQjPVcePi4t5T67gCuE",
        authDomain: "app.healthehub.com.au",
        databaseURL: "https://health-ehub.firebaseio.com",
        projectId: "health-ehub",
        storageBucket: "health-ehub.appspot.com",
        messagingSenderId: "951538947007",
        appId: "1:951538947007:web:c0cef383ceda5aca",
        measurementId: null
      };

      if (environment.production) firebaseConfig.measurementId = "G-BKSS7QNWZC";
      firebase.initializeApp(firebaseConfig);
      if (environment.production) firebase.analytics();

      if (this.platform.is("hybrid")) {
        StatusBar.setStyle({ style: StatusBarStyle.Light }).catch((err) => console.error(err));
        StatusBar.setBackgroundColor({ color: '#1867a4' }).catch((err) => console.error(err));
        this.headerColor.tint("#f4f5f8");
      };

      this.setMenuLogo();
      let user: IUser = await this.store.getUser();
      this.setMenu(user);
      this.store.getThemeCss().then((cssText: string) => {
        if (cssText != null) this.theme.setTheme(null, cssText);
      });
      SplashScreen.hide();
    });
  }

  setMenu(user: IUser) {
    if (user) {
      if (user.type != "Client") {
        if (user.type == "Admin") this.admin = true;
        this.rootUrl = "home";
        this.tenantUrl = `${user.tenant}/${user.staffId}`;
        this.menuCtrl.enable(true, 'staffSettings');
        this.menuCtrl.enable(true, 'staffMenu');
        this.menuCtrl.close();
      } else {
        this.rootUrl = "client";
        this.tenantUrl = `hub/${user.tenant}/client`;
        this.menuCtrl.close();
        this.menuCtrl.enable(false);
      }
    } else {
      this.menuCtrl.close();
      this.menuCtrl.enable(false);
    }
  }

  showSettingsMenu(): void {
    this.menuCtrl.enable(true, 'staffSettings');
  }

  showStaffMenu(): void {
    this.menuCtrl.enable(true, 'staffMenu');
  }

  async setMenuLogo(image?: string): Promise<void> {
    let url = await this.image.tenantLogo(image);
    if (url) {
      this.menuImage = url + `?stamp=${Date.now()}`;
    }
  }

  listenForEvents(): void {
    this.events.subscribe("tenant:setname", (user: IUser) => {
      if (user.staffId) this.tenantUrl = `${user.tenant}/${user.staffId}`;
      this.setMenuLogo(user.tenantImage);
      this.setMenu(user);
    });

    this.events.subscribe("user:newlogo", () => {
      this.setMenuLogo();
    });
  }

  async navTo(link: string): Promise<void> {
    let u: string = `${this.rootUrl}/${this.tenantUrl}/${link}`;
    this.navCtrl.navigateForward(u);
  }

  async logout() {
    this.menuCtrl.close();
    this.menuCtrl.enable(false, "staffMenu");
    await this.auth.logout();
    this.navCtrl.navigateBack("/");
  }
}
