import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// ionic serve                        (dev server, same as prod currently)
// ionic serve --prod                 (production server)
// ionic serve --configuration=test   (local host server)

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  api: string = "";
  aws: string = "";
  constructor() {
    this.api = environment.api;
    this.aws = environment.aws;
  }
}
