import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IInjury } from "../models/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class InjuryService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  createInjury(
    clientId: number,
    tenantId: number,
    injury: IInjury
  ): Observable<{}> {
    const data = {
      clientId,
      tenantId,
      injury
    };
    return this.http.post(`${this.config.api}createInjury`, data);
  }

  updateInjury(injury: IInjury): Observable<{}> {
    const data: any = injury;
    return this.http.put(`${this.config.api}updateInjury`, data);
  }

  getInjury(injuryId: number): Observable<IInjury> {
    let params = new HttpParams().set("injuryId", injuryId.toString());
    return this.http.get<IInjury>(`${this.config.api}getInjury`, { params });
  }

  getInjuryNotes(injuryId: number): Observable<{}> {
    let params = new HttpParams().set("injuryId", injuryId.toString());
    return this.http.get(`${this.config.api}getinjurynotes`, { params });
  }

  createPdf(data: any) {
    return this.http.post(`${this.config.api}injurypdf`, data, {
      responseType: "blob"
    });
  }
}
