import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private alert: AlertController) { }
  public async handleError(err: any) {
    let message: string;
    const type: string = typeof err.message;
    if (type != "string") {
      message = JSON.stringify(err.message);
    } else {
      message = err.message;
    };
    const alert = await this.alert.create({
      header: 'Error',
      subHeader: message,
      buttons: ['Ok']
    });
    await alert.present();
  }
}
