import { Injectable } from '@angular/core';
import { IClient, IClientView } from '../models/models';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ContactListService {
  
  constructor() { }

  groupClients(clients: IClient[], staffId: number): IClientView[] {
    let view: IClientView[] = [];
    const clientSorted = _.sortBy(clients, function(o: IClient) {
      return o.firstname.toLowerCase();
    })
    let grouped = _.groupBy(clientSorted, function (o: IClient) {
      return o.firstname.toUpperCase().substring(0, 1);
    })
    for (let prop in grouped) {
      let myclient: boolean = false;
      for (let i = 0; i < grouped[prop].length; i++) {
        if (grouped[prop][i].staffId == staffId) {
          myclient = true;
          break;
        };
      };
      view.push({
        group: prop,
        hasMyClient: myclient,
        clients: grouped[prop]
      })
    };
    return view;
  }

  groupContacts(contacts: any[]): any[] {
    let view: any[] = [];
    let grouped = _.groupBy(contacts, function (o: IClient) {
      return o.firstname.toLowerCase().substring(0, 1);
    })
    for (let prop in grouped) {
      view.push({
        group: prop,
        clients: grouped[prop]
      })
    };
    return view;
  }
}
