import { Injectable, ElementRef } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ConnectivityService } from '../../../services/connectivity.service';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  mapElement: any;
  pleaseConnect: any;
  map: any;
  mapInitialised: boolean = false;
  mapLoaded: any;
  mapLoadedObserver: any;
  currentMarker: any;
  initLocation: any;
  apiKey: string = "AIzaSyC3gql2yPuGUBwg6loVSRXJ3QOfffbl6to"; // Localhost
  // apiKey: string = "AIzaSyDVsdpaavdbZHxluLJ5GBuPkv8gDp_tpi4"; https://*.health-ehub.com/*

  constructor(public connectivityService: ConnectivityService,
    public geolocation: Geolocation) { }

  init(mapElement: ElementRef, pleaseConnect: ElementRef, initLocation: any): Promise<any> {
    this.mapElement = mapElement;
    this.pleaseConnect = pleaseConnect;
    this.initLocation = initLocation;

    return this.loadGoogleMaps();
  }

  loadGoogleMaps(): Promise<any> {
    return new Promise((resolve) => {
      if (typeof google == "undefined" || typeof google.maps == "undefined") {
        console.log("Google maps JavaScript needs to be loaded.");
        this.disableMap();

        if (this.connectivityService.isOnline()) {
          window['mapInit'] = () => {
            this.initMap().then(() => {
              resolve(true);
            });

            this.enableMap();
          }
          let script = document.createElement("script");
          script.id = "googleMaps";
          if (this.apiKey) {
            script.src = 'https://maps.google.com/maps/api/js?key=' + this.apiKey + '&callback=mapInit&libraries=places';
          } else {
            script.src = 'https://maps.google.com/maps/api/js?callback=mapInit';
          };
          document.body.appendChild(script);
        };
      } else {
        if (this.connectivityService.isOnline()) {
          this.initMap();
          this.enableMap();
        } else {
          this.disableMap();
        };
        resolve(true);
      };
      this.addConnectivityListeners();
    });
  }

  async initMap(): Promise<any> {
    this.mapInitialised = true;
    return new Promise((resolve) => {

      if (this.initLocation.lat != "" && this.initLocation.lng != "") {
        let latLng = new google.maps.LatLng(this.initLocation.lat, this.initLocation.lng);
        let mapOptions = {
          center: latLng,
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement, mapOptions);
        resolve(true);
      } else {
        this.geolocation.getCurrentPosition()
          .then((position: any) => {
            let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            let mapOptions = {
              center: latLng,
              zoom: 15,
              mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.mapElement, mapOptions);
            resolve(true);
          }).catch((err: any) => {
            let latLng = new google.maps.LatLng("-33.0000", "151.0000");
            let mapOptions = {
              center: latLng,
              zoom: 15,
              mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.mapElement, mapOptions);
            resolve(true);
          });
      };
    });
  }

  disableMap(): void {
    if (this.pleaseConnect) {
      this.pleaseConnect.style.display = "block";
    };
  }

  enableMap(): void {
    if (this.pleaseConnect) {
      this.pleaseConnect.style.display = "none";
    };
  }

  addConnectivityListeners(): void {
    this.connectivityService.watchOnline().subscribe(() => {
      setTimeout(() => {
        if (typeof google == "undefined" || typeof google.maps == "undefined") {
          this.loadGoogleMaps();
        }
        else {
          if (!this.mapInitialised) {
            this.initMap();
          };
          this.enableMap();
        };
      }, 2000);
    });
    this.connectivityService.watchOffline().subscribe(() => {
      this.disableMap();
    });
  }
}
