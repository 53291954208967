import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { ConfigService } from '../services/config.service';
import { IUser, IThemeObject } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private config: ConfigService,
    private http: HttpClient) { }

  addUser(user: IUser, tenantId: number): Observable<{}> {
    const params: any = {
      'tenantId': tenantId,
      'firstname': user.firstname,
      'lastname': user.lastname,
      'email': user.email,
      'phone': user.phone,
      'type': user.type
    };
    return this.http.post(this.config.api + 'addtenantuser', params);
  }

  setUserRole(type: string, user: IUser): Observable<any> {
    const params: any = { 'staffId': user.staffId, 'type': type };
    return this.http.post(this.config.api + 'updateusertype', params);
  }

  getUserSettings(id: number): Observable<any> {
    const params: any = { 'userId': id }
    return this.http.get(this.config.api + 'getuserdetails', { params: params });
  }

  setUserSettings(formControls: any, user: IUser): Observable<any> {
    const params: any = {
      'userId': user.userId,
      'firstname': formControls.firstname.value,
      'lastname': formControls.lastname.value,
      'email': formControls.email.value,
      'phone': formControls.phone.value
    };
    return this.http.post(this.config.api + 'updateuserdetails', params);
  }

  setTenantSettings(settings: any, user: IUser): Observable<any> {
    const params: any = {
      'tenantId': user.tenantId,
      'name': settings.name.value,
      'alias': settings.alias.value,
      'address': settings.address.value,
      'lat': settings.lat.value,
      'lng': settings.lng.value,
      'phone': settings.phone.value,
      'email': settings.email.value,
      'plan': undefined
    };
    return this.http.post(this.config.api + 'updatetenantsettings', params);
  }

  getTenantSettings(tenantId: number): Promise<any> {
    const params: any = { 'tenantId': tenantId };
    return new Promise((res: any) => {
      this.http.get(this.config.api + 'gettenantsettings', { params: params })
        .subscribe((result: any) => {
            res(result);
        })
    })
  }

  getTenantUsers(tenantId: number): Observable<any> {
    const params: any = { 'tenantId': tenantId };
    return this.http.get(this.config.api + 'gettenantusers', { params: params });
  }

  setTenantTheme(themeName: string): Observable<any> {
    const params: any = {
      'theme': themeName
    };
    return this.http.post(this.config.api + 'updatetenanttheme', params);
  }

  getTenantTheme(user: IUser): Observable<Object> {
    const params: any = { 'tenantId': user.tenantId };
    return this.http.get(this.config.api + 'gettenanttheme', { params: params });
  }

  setPassword(user: IUser, newP: string, oldP: string): Observable<any> {
    const params: any = {
      'userId': user.userId,
      'newPassword': newP,
      'password': oldP,
    };
    return this.http.post(this.config.api + 'updatepassword', params);
  }

}
