import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { StorageService } from '../services/storage.service';
import { ConfigService } from '../services/config.service';

import { IUser, ISetPasswordRequest, ILoginRequest } from '../models/models';

import * as firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private store: StorageService,
    private config: ConfigService
  ) { }

  login(email: string, password: string): Promise<any> {
    let data: ILoginRequest = {
      email: email,
      password: password
    }
    return this.http.post(this.config.api + 'login', data)
      .pipe(map((data: any) => {
        let user: IUser;
        if (data.success) {
          user = {
            type: data.user.type,
            firstname: data.user.firstname,
            lastname: data.user.lastname,
            tenantId: data.user.tenantId,
            tenant: data.user.tenant,
            email: data.user.email,
            userId: data.user.userId,
            token: data.user.token,
            staffId: data.user.staffId,
            clientId: data.user.clientId,
            tenantImage: data.user.tenantImage,
            profileImage: data.user.profileImage
          };
        };
        const model: any = {
          success: data.success,
          message: data.message,
          user: user
        };
        return model;
      })).toPromise();
  }

  async logout(): Promise<any> {
    await this.store.removeUser();
    await firebase.auth().signOut();
    return this.store.removeToken();
  }

  getTenantTheme(tenantId: number): Promise<Object> {
    let params = new HttpParams().set('tenantId', tenantId.toString());
    return this.http.get(this.config.api + 'gettenanttheme', { params }).toPromise();
  }

  getReset(email: string): Promise<{}> {
    let params = new HttpParams().set('email', email);
    return this.http.get(this.config.api + 'reset', { params }).toPromise();
  }

  setPassword(data: ISetPasswordRequest): Promise<{}> {
    return this.http.post(this.config.api + 'password', data).toPromise();
  }

  validateFbToken(token: string): Promise<{}> {
    return this.http.post(`${this.config.api}fbValidate`, { token }).toPromise();
  }
}