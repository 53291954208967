import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { IUser } from '../models/models';
import { Observable, forkJoin } from "rxjs";
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: Storage) { }

  setToken(token: string): Promise<any> {
    return this.storage.set('token', token);
  }

  getToken(): Promise<any> {
    return this.storage.get('token');
  }

  removeToken(): Promise<any> {
    return this.storage.remove('token');
  }

  getEmail(): Promise<any> {
    return this.storage.get('email');
  }

  setEmail(email: string): Promise<any> {
    return this.storage.set('email', email);
  }

  setUser(user: IUser): Promise<any> {
    return this.storage.set('user', user);
  }

  getUser(): Promise<any> {
    return this.storage.get('user');
  }

  removeUser(): Promise<any> {
    return this.storage.remove('user');
  }

  async setThemeCss(themeCssText: string): Promise<any> {
    return this.storage.set('theme', themeCssText);
  }

  getThemeCss(): Promise<string> {
    return this.storage.get('theme');
  }

  // Gets the headers from storage for making authenticated requests
  public getHeader(): Promise<any> {
    return new Promise((res, rej) => {
      firebase.auth().onAuthStateChanged(async (user: firebase.User) => {
        if(user) {
          let token = await user.getIdToken();
          res([token]);
        }
       });
    })
  }
}
