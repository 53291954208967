import { Injectable } from '@angular/core';

import { IFormBuilderForm, IFormClientForm, IFormTenantForms, IFormViewerForm, IFormViewerInput, IUser } from '../models/models';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor(private config: ConfigService,
    private http: HttpClient,
    private store: StorageService) { }

  // Complete flag not required
  // Just tenantId, form Id, name and type
  getTenantForms(tenantId: number): Observable<IFormTenantForms[]> {
    let params: HttpParams = new HttpParams().set('tenantId', tenantId.toString());
    return this.http.get<IFormTenantForms[]>(`${this.config.api}gettenantforms`, { params })
      .pipe(map((data: any) => {
        if (data.success) {
          return data.forms;
        }
      }));
  }

  // Is used when a client is selected
  // Data from the DB needs to connect 
  // the clientId with the formId and a completion status
  getClientForms(clientId: number, tenantId: number): Observable<IFormClientForm[]> {
    let params = new HttpParams().set('clientId', clientId.toString()).set('tenantId', tenantId.toString());
    return this.http.get(`${this.config.api}getclientforms`, { params })
      .pipe(
        tap(f => { console.log("getClientForms()", f) }),
        map((data: any) => {
          if (data.success) {
            let forms: any = data.forms.map((f) => {
              return {
                name: f.name,
                id: f.formId,
                clientFormId: f.clientFormId,
                complete: f.complete
              }
            });

            return forms;
          }
        }));
  }

  getForm(id: number, clientFormId?: number): Promise<IFormBuilderForm> {
    let params: HttpParams = new HttpParams().set('formId', id.toString());
    if (clientFormId) params = params.set('clientFormId', clientFormId.toString());
    return this.http.get<IFormBuilderForm>(`${this.config.api}getform`, { params })
      .pipe(map((data: any) => {
        if (data.success) {
          console.log(data)
          return data.form;
        }
      }))
      .toPromise();
  }

  updateForm(data: any) {
    return this.http.post(`${this.config.api}updateform`, data)
      .toPromise();
  }

  createForm(tenantId: number, data: any) {
    data.tenantId = tenantId;
    return this.http.post(`${this.config.api}createform`, data)
      .toPromise();
  }

  async getFormView(id: number, clientId: number): Promise<IFormViewerForm> {
    const form: IFormBuilderForm = await this.getForm(id, clientId);
    
    let inputs: IFormViewerInput[] = [];
    for (let i = 0; i < form.inputs.length; i++) {

      let valid: boolean = false;
      if (form.inputs[i].type === 'checkbox') valid = true;

      inputs.push({
        valueId: form.inputs[i].valueId,
        name: form.inputs[i].name,
        heading: form.inputs[i].heading,
        label: form.inputs[i].label,
        type: form.inputs[i].type,
        value: form.inputs[i].value,
        options: form.inputs[i].options,
        children: form.inputs[i].children,
        required: form.inputs[i].required,
        flagged: form.inputs[i].flagged,
        valid: valid,
        dirty: false
      });
    };
    const view: IFormViewerForm = {
      name: form.name,
      type: form.type,
      id: form.id,
      version: form.version,
      inputs: inputs,
      dirty: false,
      valid: false,
      complete: false
    };
    return view;
  }

  saveClientForm(form: IFormBuilderForm) {
    return this.http.post(`${this.config.api}saveclientform`, form).toPromise();
  }

  createPdf(data: any) {
    return this.http.post(`${this.config.api}formPdf`, data, {responseType: 'blob'}).toPromise();
  }

  emailPdf(data: any) {
    return this.http.post(`${this.config.api}emailPdf`, data, {responseType: 'blob'}).toPromise();
  }
}
