import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { ClientAuthGuard } from './guards/client-auth-guard.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  //https://blog.ionicframework.com/navigating-the-change-with-ionic-4-and-angular-router/
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule', canActivate: [LoginGuard] },
  { path: 'home/:tenant/:staffid', loadChildren: './client-list/client-list.module#ClientListPageModule', canActivate: [AuthGuard] },

  { path: 'hub/:tenant/login', redirectTo: 'hub/:tenant/login/error', pathMatch: 'full' },
  { path: 'hub/:tenant/:token', redirectTo: 'hub/:tenant/login/:token', pathMatch: 'full' },
  { path: 'hub/:tenant/login/:token', loadChildren: './client-login/client-login.module#ClientLoginPageModule' },
  { path: 'hub/:tenant', loadChildren: './client-home/client-home.module#ClientHomePageModule', canActivate: [ClientAuthGuard] },
  { path: 'hub/:tenant/form/:formid', loadChildren: './form-viewer/form-viewer.module#FormViewerPageModule', canActivate: [ClientAuthGuard] },

  { path: 'home/:tenant/:staffid/clients', loadChildren: './client-list/client-list.module#ClientListPageModule' },
  { path: 'home/:tenant/:staffid/client/:clientid', loadChildren: './client-tabs/client-tabs.module#ClientTabsPageModule' },
  { path: 'home/:tenant/:staffid/client/:clientid/form/:formid', loadChildren: './form-viewer/form-viewer.module#FormViewerPageModule' },
  { path: 'home/:tenant/:staffid/client/:clientid/reports', loadChildren: './report-generator/report-generator.module#ReportGeneratorPageModule' },
  { path: 'home/:tenant/:staffid/settings/team', loadChildren: './settings-admin/settings-admin.module#SettingsAdminPageModule' },
  { path: 'home/:tenant/:staffid/settings/profile', loadChildren: './settings-user/settings-user.module#SettingsUserPageModule' },
  { path: 'home/:tenant/:staffid/settings/forms', loadChildren: './form-admin/form-admin.module#FormAdminPageModule' },
  { path: 'home/:tenant/:staffid/settings/forms/editor/:formId', loadChildren: './form-builder/form-builder.module#FormBuilderPageModule' },
  { path: 'home/:tenant/:staffid/referral/contacts', loadChildren: './referral-contacts/referral-contacts.module#ReferralContactsPageModule' },
  { path: 'home/:tenant/:staffid/referral/templates', loadChildren: './referral-templates/referral-templates.module#ReferralTemplatesPageModule' },
  { path: 'home/:tenant/:staffid/referral/templates/editor/:templateId', loadChildren: './referral-template-builder/referral-template-builder.module#ReferralTemplateBuilderPageModule' },
  // ** goes last
  { path: '**', redirectTo: 'login' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
