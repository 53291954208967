import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { StorageService } from '../services/shared-services';
import { IUser } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private storage: StorageService,
    private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      let t: string = await this.storage.getToken();
      if (t) {
        let u: IUser = await this.storage.getUser();
        let url: string;
        
        if (u.token != null) {
          if (u.staffId != null) {
            url = "/home/" + u.tenant + "/" + u.staffId.toString();
          } else if (u.clientId != null) {
            url = "/hub/" + u.tenant;
          };
          // user data found, navigate 'false' from login page
          this.router.navigate([url]);
          return false;
        };
      };
      // no user data found, navigate 'true' to login page
      return true;
    } catch(err) {
      return true;
    }
  }
}
