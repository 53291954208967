import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { FileChooser } from "@ionic-native/file-chooser/ngx";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject
} from "@ionic-native/file-transfer/ngx";
import {
  File,
  LocalFileSystem,
  FileEntry,
  Entry
} from "@ionic-native/file/ngx";
import { ErrorService } from "../services/error.service";
// import { StorageService } from "../services/storage.service";
import { ConfigService } from "../services/config.service";
import { FileLikeObject } from "ng2-file-upload";
import { FilePath } from "@ionic-native/file-path/ngx";
import { IUser } from "../models/models";
import { StorageService } from "./storage.service";

// declare var LocalFileSystem: any;

@Injectable({
  providedIn: "root"
})
export class FileService {
  deviceType: string;
  constructor(
    private platform: Platform,
    private http: HttpClient,
    // private store: StorageService,
    private fileChooser: FileChooser,
    private config: ConfigService,
    // private transfer: FileTransfer,
    private file: File,
    private filePath: FilePath,
    private error: ErrorService,
    private store: StorageService
  ) {}

  async device(): Promise<string> {
    let platforms: string[] = await this.platform.platforms();

    if (platforms.includes("desktop")) {
      this.deviceType = "desktop";
    } else if (platforms.includes("android")) {
      this.deviceType = "android";
    } else if (platforms.includes("ios")) {
      this.deviceType =
        "Health e-Hub currently does not support file uploads via IOS. Please try on a desktop or laptop device.";
      this.deviceType = "ios";
    } else {
      this.deviceType =
        "Health e-Hub does not support file uploads on this device type. Please try on a desktop or laptop device.";
    }
    return this.deviceType;
  }

  // Open File Chooser then Upload Selected File
  uploadAsAndroid(fileCategory: string) {
    this.openFileChooser()
      .then(async result => {
        if (result.success) {
          console.log("File Found", result);
          let user: IUser = await this.store.getUser();
          let fileName;
          let updateId;
          if(fileCategory == 'logo') {
            fileName = user.tenantImage;
            updateId = user.tenantId;
          } else if(fileCategory == 'profile') {
            fileName = user.profileImage;
            updateId = user.userId;
          }
          let urlData = await this.getS3Url(fileCategory, result.file.type, fileName);
          if (urlData.success) {
            let url = urlData.url;
            // UPLOAD FUNCTION HERE
            await this.uploadFile(url, result.file);
            let updateImage: any = await this.saveImageUrl(
              fileCategory,
              updateId,
              urlData.key
            );
            if (updateImage.success) {
              console.log("winner winner chicken dinner");
            } else {
              console.error("No dice!");
            }
          }
        } else {
          this.error.handleError(result);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // Format taken from IONIC-AWS Starter: 
  // https://github.com/ionic-team/ionic2-starter-aws/blob/master/src/pages/account/account.ts
  dataURItoBlob(dataURI) {
    let binary = atob(dataURI.split(",")[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  }

  // Android File Chooser
  openFileChooser(): Promise<any> {
    return new Promise(async res => {
      try {
        let uri = await this.fileChooser.open();
        let fPath = await this.filePath.resolveNativePath(uri);
        this.file
          .resolveLocalFilesystemUrl(fPath)
          .then((fileInfo: FileEntry) => {
            fileInfo.file(f => {
              let reader = new FileReader();
              reader.readAsDataURL(f);
              reader.onload = () => {
                let fBlob = this.dataURItoBlob(reader.result);
                res({ success: true, message: "File Found", file: fBlob });
              };
            });
          })
          .catch(err => {
            console.error(err);
          });
      } catch (err) {
        res({ success: false, message: err });
      }
    });
  }

  // Pings api & AWS api to return a one-use-only URL to upload the image to
  getS3Url(fileCategory: string, fileType: string, fileName: string): Promise<any> {
    let params = new HttpParams();
    params = params.append("fileCategory", fileCategory);
    params = params.append("fileType", fileType);
    if(fileName != undefined && fileName != '') params = params.append('fileName', fileName);
    return this.http
      .get(`${this.config.api}uploadfile`, { params })
      .toPromise();
  }

  // Uploads the image to the AWS S3 bucket with the one-use URL
  uploadFile(url: string, file: FileLikeObject) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", file.type);
    return this.http.put(url, file, { headers }).toPromise();
  }

  // Updates the tenant record to include the newly saved image file path
  saveImageUrl(imagetype: string, updateId: Number, imageUrl: string) {
    let data = {
      imageType: imagetype,
      updateId: updateId,
      imageUrl: imageUrl
    };
    return this.http
      .post(`${this.config.api}addimageurl`, data)
      .toPromise();
  }

  // async uploadFile(fileUrl: any, fileDetails: any): Promise<void> {
  //   // https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
  //   let d = await this.device();
  //   if (d === "android") {
  //     this.uploadAndroid();
  //   } else if (d === "browser") {
  //     this.resolveFileEntry(fileDetails);
  //   };
  // }

  // async resolveFileEntry(fileDetails: any): Promise<any> {
  //   let token: string = await this.store.getToken();
  //   const fileTransfer: FileTransferObject = this.transfer.create();
  //   (window as any).requestFileSystem =
  //     (window as any).requestFileSystem ||
  //     (window as any).webkitRequestFileSystem;
  //   try {
  //     (window as any).requestFileSystem(
  //       (window as any).PERSISTENT,
  //       0,
  //       function(fs) {
  //         console.log("file system open: " + fs.name);
  //         fs.root.getFile(
  //           fileDetails.name,
  //           { create: true, exclusive: false },
  //           function(fileEntry: FileEntry) {
  //             console.log("file entry: ", fileEntry);
  //             let filePath: string = fileEntry.toURL();
  //             console.log("file path: " + filePath);
  //             let options: FileUploadOptions = {
  //               chunkedMode: false,
  //               fileKey: "file",
  //               fileName: fileDetails.name,
  //               mimeType: fileDetails.type,
  //               httpMethod: "POST",
  //               params: {
  //                 tenantId: 3,
  //                 fileType: fileDetails.name
  //               },
  //               headers: {
  //                 authorization: token
  //               }
  //             };
  //             fileTransfer
  //               .upload(
  //                 filePath,
  //                 encodeURI(`http://localhost:9000/api/v1/uploadtenantfile`),
  //                 options
  //               )
  //               .then(
  //                 data => {
  //                   // success
  //                   console.log(data);
  //                 },
  //                 err => {
  //                   // error
  //                   this.error.handleError(err);
  //                 }
  //               );
  //           },
  //           function(err) {
  //             console.error("error getting file! " + err);
  //           }
  //         );
  //       },
  //       function(e) {
  //         console.log(e);
  //         this.error.handleError(e);
  //       }
  //     );
  //   } catch (err) {
  //     console.log(err);
  //     // return null;
  //   }
  // }
}
