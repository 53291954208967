import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor() { }

  //Email Validation
  checkEmail(control: FormControl): ValidatorFn {
    let result = null;
    let regEx: RegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let valid = regEx.test(control.value);
    if (control.value == '' || valid != true) {
      result = { 'invalidEmail': true };
    }
    return result;
  }
  //Hex Color Validation
  checkHexString(control: FormControl): ValidatorFn {
    let result = null;
    let regEx: RegExp = /^#[A-Fa-f0-9]{6}/;
    let valid = regEx.test(control.value);
    if (control.value == '' || valid != true) {
      result = { 'invalidEmail': true };
    }
    return result;
  }

  checkPassword(control: FormControl): ValidatorFn {
    let result = null;
    let regEx: RegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]{5,20}/;
    let valid = regEx.test(control.value);
    if (control.value == '' || valid != true) {
      result = { 'invalidEmail': true };
    }
    return result;
  }

  checkBusinessName(control: FormControl): ValidatorFn {
    let result = null;
    let regEx: RegExp = /^[a-zA-Z0-9._-\s]{3,20}/;
    let valid = regEx.test(control.value);
    if (control.value == '' || valid != true) {
      result = { 'invalidEmail': true };
    }
    return result;
  }

  checkAlias(control: FormControl): ValidatorFn {
    let result = null;
    let regEx: RegExp = /^[a-zA-Z]{5,16}/;
    let valid = regEx.test(control.value);
    if (control.value == '' || valid != true) {
      result = { 'invalidEmail': true };
    }
    return result;
  }

  checkPhone(control: FormControl): ValidatorFn {
    let result = null;
    let regEx: RegExp = /^[0-9-+()\s]/;
    let valid = regEx.test(control.value);
    if (control.value == '' || valid != true) {
      result = { 'invalidEmail': true };
    }
    return result;
  }

  compareControls(targetKey: string, toMatchKey: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const target = group.controls[targetKey];
      const toMatch = group.controls[toMatchKey];
      if (target.touched) {
        const isMatch = target.value === toMatch.value;
        // set equal value error on dirty controls
        if (!isMatch && target.valid && toMatch.valid) {
          toMatch.setErrors({ equalValue: targetKey });
          const message = targetKey + ' != ' + toMatchKey;
          return { 'equalValue': message };
        };
        if (isMatch && toMatch.hasError('equalValue')) {
          toMatch.setErrors(null);
        };
      };
      return null;
    };
  }
}
