import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { switchMap, retry } from "rxjs/operators";
import { StorageService } from "../services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private store: StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.indexOf('amazon') > -1) return next.handle(request);
    return from(this.store.getHeader())
      .pipe(
        retry(2),
        switchMap(data => {
          let params = request.params;
          let headers = request.headers.append('Content-Type', 'application/json');
          if (data[0] && request.url.indexOf('amazon') == -1) headers = request.headers.set('authorization', data[0].toString());
          const requestClone = request.clone({
            headers,
            params
          });
          return next.handle(requestClone);
        })
      );
  }
}
