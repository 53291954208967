import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import * as firebase from "firebase/app";
import "firebase/analytics";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  login(userId: string, method: string) {
    if (environment.production) {
      firebase.analytics().setUserId(userId);
      firebase.analytics().logEvent("login", { method: method });
    }
  }

}
