import { Injectable, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';

import { Plugins } from '@capacitor/core';
const { StatusBar } = Plugins;

import { StorageService } from '../services/storage.service';
import { ITheme, IThemeObject, IUser } from '../models/models';

import * as Color from 'color';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {

  constructor(@Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private store: StorageService,
    private http: HttpClient,
    private config: ConfigService) { }

  saveAndSetLocalThemeCss(theme: IThemeObject) {
    const cssText: string = this.CSSTextGenerator(theme.value);
    this.store.setThemeCss(cssText);
    this.setTheme(null, cssText);
  }

  saveLocalThemeCss(theme: IThemeObject) {
    const cssText: string = this.CSSTextGenerator(theme.value);
    this.store.setThemeCss(cssText);
  }

  // Override all global variables with a new theme
  // Pass in a IThemeObject or raw CSS String Variables as String
  async setTheme(theme: IThemeObject, cssString?: string): Promise<boolean> {
    let themeCssText: string;
    if (theme != null) {
      themeCssText = await this.CSSTextGenerator(theme.value);
    } else {
      themeCssText = cssString;
    }
    await this.setGlobalCSS(themeCssText);
    return true;
  }

  private setGlobalCSS(css: string) {
    this.document.documentElement.style.cssText = css;
  }

  private setStatusBarColor(primary: string) {
    if (this.platform.is("hybrid")) StatusBar.setBackgroundColor({ color: primary });
  }

  private setMetaColor(primary: string) {
    let meta: any = this.document.head.querySelector("meta[name='theme-color']");
    if (meta != null) meta.content = primary;
  }

  private contrast(colorstring: string, ratio: number = 0.9) {
    let color: any = Color(colorstring);
    const testColor = color.darken(0.1); // Adjust to lean towards white contrast fonts
    return testColor.isDark() ? "#FFFFFF" : color.darken(ratio);
  }

  CSSTextGenerator(colors: ITheme): string {

    const {
      primary,
      secondary,
      tertiary,
      success,
      warning,
      danger,
      dark,
      medium,
      light
    } = colors;

    const shadeRatio = 0.1;
    const tintRatio = 0.1;

    this.setStatusBarColor(primary);
    this.setMetaColor(primary);

    return `--ion-color-base: ${light};
--ion-color-contrast: ${dark};
/** primary **/
--ion-color-primary: ${primary};
--ion-color-primary-rgb: ${Color(primary).red()},${Color(primary).green()},${Color(primary).blue()};
--ion-color-primary-contrast: ${this.contrast(primary)};
--ion-color-primary-contrast-rgb: 255,255,255;
--ion-color-primary-shade: ${Color(primary).darken(shadeRatio)};
--ion-color-primary-tint: ${Color(primary).lighten(tintRatio)};
/** secondary **/
--ion-color-secondary: ${secondary};
--ion-color-secondary-rgb: ${Color(secondary).red()},${Color(secondary).green()},${Color(secondary).blue()};
--ion-color-secondary-contrast: ${this.contrast(secondary)};
--ion-color-secondary-contrast-rgb: 255, 255, 255;
--ion-color-secondary-shade: ${Color(secondary).darken(shadeRatio)};
--ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio)};
/** tertiary **/
--ion-color-tertiary: ${tertiary};
--ion-color-tertiary-rgb: ${Color(tertiary).red()},${Color(tertiary).green()},${Color(tertiary).blue()};
--ion-color-tertiary-contrast: ${this.contrast(tertiary)};
--ion-color-tertiary-contrast-rgb: 255, 255, 255;
--ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
--ion-color-tertiary-tint: ${Color(tertiary).lighten(tintRatio)};
/** success **/
--ion-color-success: ${success};
--ion-color-success-rgb: ${Color(success).red()},${Color(success).green()},${Color(success).blue()};
--ion-color-success-contrast: ${this.contrast(success)};
--ion-color-success-contrast-rgb: 255, 255, 255;
--ion-color-success-shade: ${Color(success).darken(shadeRatio)};
--ion-color-success-tint: ${Color(success).lighten(tintRatio)};
/** warning **/
--ion-color-warning: ${warning};
--ion-color-warning-rgb: ${Color(warning).red()},${Color(warning).green()},${Color(warning).blue()};
--ion-color-warning-contrast: ${this.contrast(warning)};
--ion-color-warning-contrast-rgb: 255, 255, 255;
--ion-color-warning-shade: ${Color(warning).darken(shadeRatio)};
--ion-color-warning-tint: ${Color(warning).lighten(tintRatio)};
/** danger **/
--ion-color-danger: ${danger};
--ion-color-danger-rgb: ${Color(danger).red()},${Color(danger).green()},${Color(danger).blue()};
--ion-color-danger-contrast: ${this.contrast(danger)};
--ion-color-danger-contrast-rgb: 255, 255, 255;
--ion-color-danger-shade: ${Color(danger).darken(shadeRatio)};
--ion-color-danger-tint: ${Color(danger).lighten(tintRatio)};
/** dark **/
--ion-color-dark: ${dark};
--ion-color-dark-rgb: ${Color(dark).red()},${Color(dark).green()},${Color(dark).blue()};
--ion-color-dark-contrast: ${this.contrast(dark)};
--ion-color-dark-contrast-rgb: 255, 255, 255;
--ion-color-dark-shade: ${Color(dark).darken(shadeRatio)};
--ion-color-dark-tint: ${Color(dark).lighten(tintRatio)};
/** medium **/
--ion-color-medium: ${medium};
--ion-color-medium-rgb: ${Color(medium).red()},${Color(medium).green()},${Color(medium).blue()};
--ion-color-medium-contrast: ${this.contrast(medium)};
--ion-color-medium-contrast-rgb: 255, 255, 255;
--ion-color-medium-shade: ${Color(medium).darken(shadeRatio)};
--ion-color-medium-tint: ${Color(medium).lighten(tintRatio)};
/** light **/
--ion-color-light: ${light};
--ion-color-light-rgb: ${Color(light).red()},${Color(light).green()},${Color(light).blue()};
--ion-color-light-contrast: ${this.contrast(light)};
--ion-color-light-contrast-rgb: 0, 0, 0;
--ion-color-light-shade: ${Color(light).darken(shadeRatio)};
--ion-color-light-tint: ${Color(light).lighten(tintRatio)};`;
  }

  // saveAndSetLocalThemeCss(theme: IThemeObject) {
  //   const cssText: string = this.CSSTextGenerator(theme.value);
  //   this.store.setThemeCss(cssText);
  //   this.setTheme(null, cssText);
  // }

  async saveThemeDb(theme: IThemeObject) {
    let user: IUser = await this.store.getUser();
    let data = {
      tenantId: user.tenantId,
      color: theme.color,
      name: theme.name,
      value: theme.value
    };
    let saved = await this.http.post(`${this.config.api}updatetenanttheme`, data).toPromise();
  }
}
